<template>
  <div>
  <v-btn 
    block 
    color="green" 
    dark
    @click="DataInvoice"
  >
    Xem hóa đơn
  </v-btn>
    <v-dialog 
      v-model="boxHoaDon"
      max-width="900px"
    >
      <v-responsive aspect-ratio="16/9">
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              dark
              @click="boxHoaDon = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Hóa đơn</v-toolbar-title>
            <v-spacer />
            <v-toolbar-title>
              <v-btn-toggle>
                <v-btn color="green" @click="editMode = true" v-if="editMode === false"><v-icon>mdi-circle-edit-outline</v-icon></v-btn>
                <v-btn color="green" @click="editMode = false" v-else><v-icon>mdi-content-save</v-icon></v-btn>
                <v-btn color="green" @click="print"><v-icon>mdi-printer-check</v-icon></v-btn>
              </v-btn-toggle>
            </v-toolbar-title>
          </v-toolbar>
          
          <v-card-title></v-card-title>
          <div ref="printMe">
            <v-card-text>
              <v-row>
                <v-col
                  cols=6
                >
                  <h1>Hóa đơn chuyến: <span class="indigo--text">{{item.Code}}</span></h1>
                  <br>
                  <h2>Ngày tạo: {{TimeNow().format('YYYY/MM/DD')}}</h2>
                </v-col>
                <v-col
                  cols=6
                >
                  <v-img
                    max-height="350"
                    max-width="350"
                    src="/logo-tomexpress.png"
                  />
                </v-col>
              </v-row>
              <br>
              <v-divider></v-divider>
              <br>
              <v-row>
                <v-col
                  cols=5
                >
                  <p class="text-h6"><strong>Thông tin người gửi (JP)</strong></p>
                  <p class="text-body-1"><strong>Tên:</strong> {{ item.Customer.NameJP }}</p>
                  <p class="text-body-1" v-if="item.Customer.AddressJP"><strong>Địa chỉ:</strong> {{ item.Customer.AddressJP }}</p>
                  <p class="text-body-1"><strong>Sđt:</strong> {{ item.Customer.PhoneJP }}</p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols=5
                  v-if="Address !== false || editMode === true || AddressList.length === 1 || CheckDiaChi.length === 3"
                >
                  <template v-if="CheckDiaChi.length === 3">
                    <p class="text-h6"><strong>Thông tin người nhận (VN)</strong></p>
                    <p class="text-body-1"><strong>Tên:</strong> {{ CheckDiaChi[0] }}</p>
                    <p class="text-body-1"><strong>Địa chỉ:</strong> {{ CheckDiaChi[1] }}</p>
                    <p class="text-body-1"><strong>Sđt:</strong> {{ CheckDiaChi[2] }}</p>
                  </template>
                  <template v-else>
                    <template v-if="AddressList.length > 1">
                      <template v-if="editMode === true">
                        <v-select
                          v-model="Address"
                          :items="AddressList"
                          item-value="code"
                          item-text="text"
                          label="Địa chỉ"
                        />
                        <br>
                      </template>
                      <template v-if="Address !== false">
                        <p class="text-h6"><strong>Thông tin người nhận (VN)</strong></p>
                        <p class="text-body-1"><strong>Tên:</strong> {{ Address.Name }}</p>
                        <p class="text-body-1"><strong>Địa chỉ:</strong> {{ Address.Address }}</p>
                        <p class="text-body-1"><strong>Sđt:</strong> {{ Address.Phone }}</p>
                      </template>
                    </template>
                    <template v-else>
                      <p class="text-h6"><strong>Thông tin người nhận (VN)</strong></p>
                      <p class="text-body-1"><strong>Tên:</strong> {{ AddressList[0].Name }}</p>
                      <p class="text-body-1"><strong>Địa chỉ:</strong> {{ AddressList[0].Address }}</p>
                      <p class="text-body-1"><strong>Sđt:</strong> {{ AddressList[0].Phone }}</p>
                    </template>
                  </template>
                </v-col>
              </v-row>
              <br>
              <v-divider></v-divider>
              <br>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        TT
                      </th>
                      <th class="text-center">
                        Mã lô hàng
                      </th>
                      <th class="text-center">
                        Khối lượng
                      </th>
                      <th class="text-center">
                        Đơn giá
                      </th>
                      <th class="text-center">
                        Phụ phí
                      </th>
                      <th class="text-center">
                        Thành tiền
                      </th>
                      <template v-if="editMode === true">
                        <th class="text-center">
                          Người nhận
                        </th>
                        <th class="text-center">
                          Thao tác
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template 
                        v-for="(item,index) in DanhSachMoi"
                    >
                      <tr
                        :key="index"
                      >
                        <template v-if="deleted[index] === undefined || editMode === true">
                          <td class="text-center"><template v-if="deleted[index] === undefined">{{ item.index }}</template></td>
                          <td class="text-center">{{ item.Code }}</td>
                          <td class="text-center">{{ item.W }}</td>
                          <td class="text-center">{{ DonGia(item) }}</td>
                          <td class="text-center">
                            <v-list-item
                              v-for="(dlPT, i) in item.PhuThu"
                              :key="i"
                              link
                            >
                              <v-list-item-subtitle class="text-center">{{dlPT.Muc}}</v-list-item-subtitle>
                              <v-list-item-action-text class="text-center">
                                {{ DoiTien(dlPT.Phi) }}
                              </v-list-item-action-text>
                            </v-list-item>
                            <template v-if="item.PhuThu.length > 0">
                              <v-divider />
                              <v-list-item>
                                <v-list-item-subtitle class="text-center"><strong>Tổng cộng: </strong></v-list-item-subtitle>
                                <v-list-item-action-text class="text-center">
                                  {{ DoiTien(item.TongPhuThu) }}
                                </v-list-item-action-text>
                              </v-list-item>
                            </template>
                          </td>
                          <td class="text-center">{{ ThanhTien(item) }}</td>
                        </template>
                        <template v-if="editMode === true">
                              <td class="text-center">
                                <p>{{item.Shipping.Name}}</p>
                                <p>{{item.Shipping.Address}}</p>
                                <p>{{item.Shipping.Phone}}</p>
                              </td>
                              <td class="text-center" v-if="deleted[index] === undefined">
                                <v-btn
                                  color="red"
                                  dark
                                  x-small
                                  @click="Hide(index)"
                                >
                                  {{deleted[index]}}
                                  Ẩn
                                  <v-icon right>
                                    mdi-trash-can
                                  </v-icon>
                                </v-btn>
                              </td>
                              <td class="text-center" v-else>
                                <v-btn
                                  color="green"
                                  dark
                                  x-small
                                  @click="Show(index)"
                                >
                                  Hiện
                                  <v-icon right>
                                    mdi-delete-restore
                                  </v-icon>
                                </v-btn>
                              </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
              <br>
              <v-card
                class="d-flex mb-2 flex-row-reverse"
                flat
                tile
              >
                <p class="text-h5">Thành tiền: {{ ThanhTienInvoice }}</p>
              </v-card>
            </v-card-text>
            <v-card-subtitle>
              <p class="text-h6" v-if="item.Tracking">
                Mã vận đơn: {{item.Tracking}}
              </p>
              <p class="text-h5">Thông tin thanh toán</p>
              <p class="text-body-1">Tài khoản VN</p>
              <p class="text-body-2">Tên TK: <strong>{{ loginInfo.Bank.TenTK }}</strong> </p>
              <p class="text-body-2">Số TK: <strong>{{ loginInfo.Bank.STK }}</strong> </p>
              -{{ loginInfo.Bank.Bank }} <br>
              <span class="red--text">
                -Mã tracking sẽ được phát hành khi hàng hóa của quý khách thông quan vào nội địa Việt Nam <br>
                -Quý khách vui lòng ghi nội dung chuyển khoản là số hóa đơn<br>
                -Số tiền phải chính xác như trên, không làm tròn. Xin cảm ơn !
              </span> 
            </v-card-subtitle>
          </div>
          <Loading :status="loading" />
        </v-card>
      </v-responsive>
    </v-dialog>
  </div>
</template>

<script>
import { DoiTien } from '@/plugins/helper/string'
import { now } from '@/plugins/helper/timezone'
import { Cuoc } from '@/plugins/helper/tinhtien.js'
import Loading from '@/components/base/Loading.vue'
import {mapGetters } from 'vuex'
export default {
  components:{
    Loading,
  },
  data () {
    return {
      boxHoaDon: false,
      editMode: false,
      Address: false,
      loading: false,
      deleted: {

      },
      stt:0,
      totalPackage: 0,
      PackageName: '',
      DanhSachDiaChi: [],
    }
  },
  computed:{
    ...mapGetters(['loginInfo']),
    CheckDiaChi(){
      return (this.DanhSachDiaChi.length === 1) ? this.DanhSachDiaChi[0].split('|'):[]
    },
    DanhSachMoi(){
      let id=0;
      return this.item.Items.map((item,index)=>{
        if(this.deleted[index] === undefined) id+=1
        this.ThemDiaChiMoi(item.Shipping)
        return {
          ...item,
          index: id,
        }
      })
    },
    ThanhTienInvoice(){
      let total = 0; this.resetTotalPackage();
      this.item.Items.map((i,index)=>{
        if(this.deleted[index] === undefined){
          total += this.ThanhTien(i,false)
          this.resetTotalPackage(true)
          if(this.totalPackage === 1){
            this.UpdatePackageName(i.Code)
          }
        }
      })
      return DoiTien(total)
    },
    AddressList(){
      let Address = []
      this.item.Items.map((item)=>{
        const _Address = item.Shipping
        Address.push({..._Address,code:_Address,text:`${_Address.Name}-${_Address.Address}-${_Address.Phone}`})
      })
      return Address
    }
  },
  methods:{
    TimeNow:now,
    DoiTien,
    ThemDiaChiMoi(diachi){
      const DiaChi = `${diachi.Name}|${diachi.Address}|${diachi.Phone}`
      if(this.DanhSachDiaChi.includes(DiaChi) === false) this.DanhSachDiaChi.push(DiaChi)
      console.log(this.DanhSachDiaChi)
    },
    resetTotalPackage(count=false){
      if(count === false){
        this.totalPackage = 0;
      }else{
        this.totalPackage += 1;
      }
    },
    UpdatePackageName(name){
      this.PackageName = name
    },
    async print() {
      this.loading = true
      const el = this.$refs.printMe;
      const options = {
        allowTaint: true,
        type: 'dataURL'
      }
      let a = document.createElement("a");
      a.href = await this.$html2canvas(el, options);
      const Name = (this.totalPackage !== 1) ? this.item.Code+"-"+this.item.Customer.Username:this.PackageName
      a.download = Name+".png";
      a.click()
      this.loading = false
    },
    Hide(index){
      this.deleted = {
        ...this.deleted,
        [index]:true
      }
      this.$forceUpdate()
    },
    Show(index){
      this.deleted = {
        ...this.deleted,
        [index]:undefined
      }
      this.$forceUpdate()
    },
    ThanhTien(item,doitien=true){
      const {GiaSale} = Cuoc({item});
      return (doitien) ? DoiTien(GiaSale):GiaSale;
      // const GiaBanSale = parseInt(this.item.Price)
      // const GiaVon = (item.CustomShipment) ? parseInt(item.CustomShipment.Sale):0
      // const GiaGocSale = parseInt(item.Sale)
      // const GiaGoc = (GiaBanSale > GiaGocSale) ? GiaBanSale:GiaGocSale
      // const KL = parseFloat(item.W)
      // let Cuoc = (GiaVon === 0) ? KL*GiaGoc:KL*parseInt(GiaVon)
      // Cuoc += item.TongPhuThu
      // return (doitien) ? DoiTien(Cuoc):Cuoc
    },
    DonGia(item){
      const {Sale} = Cuoc({item});
      return DoiTien(Sale);
      // const GiaBanSale = parseInt(this.item.Price)
      // const GiaVon = (item.CustomShipment) ? parseInt(item.CustomShipment.Sale):0
      // const GiaGocSale = parseInt(item.Sale)
      // const GiaGoc = (GiaBanSale > GiaGocSale) ? GiaBanSale:GiaGocSale
      // return DoiTien((GiaVon === 0) ? GiaGoc:GiaVon)
    },
    DataInvoice(){
      this.boxHoaDon = true
      this.deleted = []
    },
    // OK(index){
    //   const result = (this.deleted['del'+index] === undefined) ? true:false
    //   return result
    // },
  },
  props: ['item'],
}
</script>

<style>

</style>