<template>
  <div>
    <v-container>
      <v-toolbar color="#0f2e8c" flat><h3 class="white--text">Báo cáo chuyến hàng: {{Code}} </h3></v-toolbar>
      <v-card-title>
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Tìm kiếm"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <br>
      <template v-if="DanhSachMoi.length > 0">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="DanhSachMoi"
          :search="search"
          loading-text="Đang tải dữ liệu..."
          :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
          :items-per-page="20"
        >
          <template v-slot:[`body.prepend`]>
            <tr>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale_Da_Thanh_Toan)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale-Tong_Cuoc_Sale_Da_Thanh_Toan)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale-Tong_Cuoc)}}
              </td>
            </tr>
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale_Da_Thanh_Toan)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale-Tong_Cuoc_Sale_Da_Thanh_Toan)}}
              </td>
              <td class="text-h5 text-center">
                {{DoiTien(Tong_Cuoc_Sale-Tong_Cuoc)}}
              </td>
            </tr>
          </template>
					<template v-slot:[`item.SentInvoice`]="{ item }">
						<v-checkbox
							v-model="item.SentInvoice"
							:label="item.SentInvoice ? 'Đã gửi':'Chưa gửi'"
							:color="item.SentInvoice ? 'green':'orange'"
							@change="doInvoiceStatus(item.SentInvoice,item.Customer.Username)"
						/>
					</template>
          <template v-slot:[`item.HoaDon`]="{ item }">
            <Invoice :item="item" />
          </template>
          <template v-slot:[`item.CuocGoc`]="{ item }">
            {{ DoiTien(item.CuocGoc) }}
          </template>
          <template v-slot:[`item.CuocBan`]="{ item }">
            {{ DoiTien(item.CuocBan) }}
          </template>
          <template v-slot:[`item.Cuoc`]="{ item }">
            {{ DoiTien(item.Cuoc) }}
          </template>
          <template v-slot:[`item.KhachPhaiTra`]="{ item }">
            {{ DoiTien(item.CuocSale[0]) }}
          </template>
          <template v-slot:[`item.KhachDaTra`]="{ item }">
            {{ DoiTien(item.CuocSale[1]) }}
          </template>
          <template v-slot:[`item.CuocSale`]="{ item }">
            {{ CongNo(item.CuocSale) }}
          </template>
          <template v-slot:[`item.LoiNhuan`]="{ item }">
            {{ LoiNhuan(item.Cuoc,item.CuocSale[0]) }}
          </template>
        </v-data-table>
      </template>
      <v-alert
        border="top"
        color="red lighten-2"
        dark
        v-else
      >
        Xin lỗi! hiện tại chưa có thông tin báo cáo cho chuyến hàng này
      </v-alert>
    </v-container>
  </div>
</template>

<script>

import Global from '@/plugins/query/global'
import Query from '@/plugins/query/report'
import { Status } from '@/plugins/helper/dstatus'
import { DoiTien } from '@/plugins/helper/string'
import { now } from '@/plugins/helper/timezone'
import { CountTotal } from '@/plugins/helpers'
import { Cuoc } from '@/plugins/helper/tinhtien.js'
import {mapGetters } from 'vuex'
import Invoice from '@/components/report/InvoiceSale.vue'
import {InvoiceStatusByShipment, InvoiceStatusUpdate} from "../plugins/query/global";

export default {
  name: 'SaleReport',
  components: {
    Invoice,
  },
  data () {
    return {
      notice: new Status(this.$swal),
      shipmentID: undefined,
      search: '',
      loading: false,
      Code:undefined,
      reports: [],
      headers: [
        { text: '#', value: 'index', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Hóa đơn', value: 'HoaDon', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Tên KH', value: 'Customer.NameJP', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Mã KH', value: 'Customer.Username', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
				{ text: 'Hóa đơn', value: 'SentInvoice', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Khối lượng', value: 'TongKL', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Tom Express', value: 'Cuoc', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Khách phải trả', value: 'KhachPhaiTra', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Khách đã trả', value: 'KhachDaTra', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Công nợ', value: 'CuocSale', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
        { text: 'Lợi nhuận', value: 'LoiNhuan', align: 'center',width: "auto",class:"text-center grey lighten-2 black--text" },
      ],
      itemsPerPage: 3,
      boxHoaDon: false,
      Address: false,
      editMode: false,
      customer: false,
      dataInvoice: [],
      deleted: [],
      output: null,
      Tong_Cuoc: 0,
      Tong_Cuoc_Sale: 0,
      Tong_Cuoc_Sale_Da_Thanh_Toan: 0,
			invoice_status: [],
    }
  },
  computed: {
    ...mapGetters(['loginInfo']),
    Bank(){
      if(this.loginInfo.Bank === undefined) return{
        STK: '',
        TenTK: '',
        Bank: '',
      }
      return this.loginInfo.Bank
    },
    DiaChi: function(){
      this.ResetCount()
      return this.dataInvoice.map(
      (item, index) => {       
        const r = {
          ...item,
          index: index+1,
          Cuoc: this.TongCuoc(index),
          CuocSale: this.TongCuocSale(index),
        }
        return r
      })
    },
    DanhSachMoi: function(){
      return this.reports.map(
      (item, index) => {    
        const TongPhuThu = parseInt(this.TongPhuThu(index))
        const TongCuoc = this.TongCuoc(index)+TongPhuThu
        const CuocSale = this.TongCuocSale(index,TongPhuThu)
        const r = {
          ...item,
          index: index+1,
          Cuoc: TongCuoc,
          CuocSale: CuocSale,
          TongKL: this.CountTotal(item.Items,'W'),
          PhuThu: TongPhuThu,
					SentInvoice: !!this.invoice_status.find(el=> el.Invoice === item.Customer.Username),
        }
        return r
      })
  },
  },
  watch:{
  },
  methods: {
    ...Global,
    ...Query,
    DoiTien:DoiTien,
    TimeNow:now,
    CountTotal:CountTotal,
    ResetCount(){
      this.Tong_Cuoc = 0;this.Tong_Cuoc_Sale=0; this.Tong_Cuoc_Sale_Da_Thanh_Toan = 0;
    },
		doInvoiceStatus(Status,Invoice){
			return this.InvoiceStatusUpdate(this.shipmentID,{Status,Invoice});
		},
    ThanhToan(item,status){
      const newData = {
        Status: status,
        ID: item.index-1,
      }
      this.TT('thanh-toan/'+this.shipmentID,newData).then(json=>{
        if(json.success !== true)
        {
          const e = json.error || "Không thể cập nhật tình trạng thanh toán !!!"
          this.notice.error(e)
        }else{
          this.LayDuLieu()
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = true
      })
    },
    TongKL(index){
      return CountTotal(this.reports[index].Items,'W')
    },
    TongPhuThu(index){
      return CountTotal(this.reports[index].Items,'TongPhuThu')
    },
    TongCuoc(index){
      // const GiaGoc = parseInt(this.reports[index].Price)
      let TongCuoc = 0
      this.reports[index].Items.map(item=>{
        const {GiaGoc} = Cuoc({item});
        TongCuoc += GiaGoc;
        // const GiaVon = parseInt(item.CustomShipment.Von)
        // if(GiaVon === 0){
        //   TongCuoc += parseFloat(item.W)*GiaGoc
        // }else{
        //   TongCuoc += parseFloat(item.W)*parseInt(GiaVon)
        // }
      })
      this.Tong_Cuoc += TongCuoc
      return TongCuoc
    },
    TongCuocSale(index){
      let TongCuoc = 0
      let DaThanhToan = 0
      // const CuocGocSale = parseInt(this.reports[index].Customer.Sale.Shipment)
      this.reports[index].Items.map(item=>{
        const {GiaSale} = Cuoc({item});
        // const GiaVon = parseInt(item.CustomShipment.Sale)
        // const GiaGocBan = parseInt(item.Sale)
        // const GiaGoc = (CuocGocSale > GiaGocBan) ? CuocGocSale:GiaGocBan
        // const Cuoc = (GiaVon === 0) ? parseFloat(item.W)*GiaGoc:parseFloat(item.W)*parseInt(GiaVon)
        TongCuoc += GiaSale
        if(item.Status === 'da_thanh_toan'){
          DaThanhToan += GiaSale
        }
      })
      // TongCuoc += TongPhuThu;
      this.Tong_Cuoc_Sale += TongCuoc;
      this.Tong_Cuoc_Sale_Da_Thanh_Toan += DaThanhToan;
      return [TongCuoc,DaThanhToan]
    },
    LoiNhuan(tong,sale){
      return DoiTien(sale-tong)
    },
    CongNo(cuoc){
      return DoiTien(cuoc[0]-cuoc[1])
    },
    LayDuLieu() {
      this.loading = true
      Promise.all([
        this.Tai(),
				this.TaiStatusInvoice(),
      ]).finally(()=>{
        this.loading = false
      })
    },
    Tai(){
      return this.ReportBySale(this.shipmentID).then(resp=>{
        if(resp.success === true && resp.data.length > 0){
          console.log('Downloaded report data !!!')
          this.Code = resp.data[0].Code
          this.reports = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin báo cáo"
          this.notice.error(msg)
          this.$router.push('/')
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
		TaiStatusInvoice(){
			return this.InvoiceStatusByShipment(this.shipmentID).then(resp=>{
				if(resp.success === true && resp.data.length > 0){
					console.log('Downloaded status invoice data !!!')
					this.invoice_status = resp.data
				}
			})
				.catch(e=>{
					this.notice.error(e)
				})
		},
  },
  created(){
    this.shipmentID = this.$route.params.shipmentID
    this.LayDuLieu()
  }
}
</script>
